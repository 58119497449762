<template>
  <router-view />
</template>

<script>
import "./frontapp/scss/tailwind.scss";
import NetteAjax from "nette-base-module/js/nette/NetteAjax";

export default {
  setup () {
    NetteAjax.init()
  },
}
</script>
