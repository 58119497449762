import { createRouter, createWebHistory } from 'vue-router'
import store from 'nette-base-module/js/store'

const BackLayout = () => import('@/backapp/views/Layout.vue')
const FrontLayout = () => import(/* webpackPreload: true */ '@/frontapp/views/Layout.vue')
const Folder = () => import(/* webpackPreload: true */ '@/frontapp/views/Folder.vue')
const FolderTrash = () => import(/* webpackPreload: true */ '@/frontapp/views/FolderTrash.vue')
const Document = () => import(/* webpackPreload: true */ '@/frontapp/views/Document.vue')
const BcGallery = () => import(/* webpackPreload: true */ '@/frontapp/views/BcGallery.vue')
const Search = () => import(/* webpackPreload: true */ '@/frontapp/views/Search.vue')

const routes = [
  {
    path: '/admin/:pathMatch(.*)*',
    component: BackLayout,
  },
  {
    path: '/:lang([a-z]{2})?/folder',
    meta: {
      netteRoute: '/<lang>/folder',
      trackPageView: false, // do not automatically track page view
    },
    component: FrontLayout,
    name: 'BCFolder',
    children: [
      {
        path: '/:lang([a-z]{2})?/folder/:rootId/:id',
        component: Folder,
        props: true,
      }
    ]
  },
  {
    path: '/:lang([a-z]{2})?/shared-folder',
    meta: {
      netteRoute: '/<lang>/shared-folder',
      trackPageView: false, // do not automatically track page view
    },
    component: FrontLayout,
    name: 'BCSharedFolder',
    children: [
      {
        path: '/:lang([a-z]{2})?/shared-folder/:rootId/:id',
        component: Folder,
        props: true,
      }
    ]
  },
  {
    path: '/:lang([a-z]{2})?/trash',
    meta: {
      netteRoute: '/<lang>/trash',
      trackPageView: false, // do not automatically track page view
    },
    component: FrontLayout,
    name: 'BCTrash',
    children: [
      {
        path: '/:lang([a-z]{2})?/trash/:rootId/:id',
        component: FolderTrash,
        props: true,
      }
    ]
  },
  {
    path: '/:lang([a-z]{2})?/trash-document',
    meta: {
      netteRoute: '/<lang>/trash-document',
      trackPageView: false, // do not automatically track page view
    },
    component: FrontLayout,
    name: 'BCTrashDocument',
    children: [
      {
        path: '/:lang([a-z]{2})?/trash-document/:rootId/:id',
        component: Document,
        props: true,
        children: [
          {
            path: '/:lang([a-z]{2})?/trash-document/:rootId/:id/gallery',
            component: BcGallery,
            props: true,
          }
        ]
      },
      {
        path: '/:lang([a-z]{2})?/trash-document/:rootId/:id/:revId([0-9]+)',
        component: Document,
        props: true,
        children: [
          {
            path: '/:lang([a-z]{2})?/trash-document/:rootId/:id/:revId([0-9]+)/gallery',
            component: BcGallery,
            props: true,
          }
        ]
      }
    ]
  },
  {
    path: '/:lang([a-z]{2})?/document',
    meta: {
      netteRoute: '/<lang>/document',
      trackPageView: false, // do not automatically track page view
    },
    component: FrontLayout,
    name: 'BCDocument',
    children: [
      {
        path: '/:lang([a-z]{2})?/document/:rootId/:id',
        component: Document,
        name: 'BCDocumentDetail',
        props: true,
        children: [
          {
            path: '/:lang([a-z]{2})?/document/:rootId/:id/gallery',
            component: BcGallery,
            props: true,
          }
        ]
      },
      {
        path: '/:lang([a-z]{2})?/document/:rootId/:id/:revId([0-9]+)',
        component: Document,
        name: 'BCDocumentDetailRev',
        props: true,
        children: [
          {
            path: '/:lang([a-z]{2})?/document/:rootId/:id/:revId([0-9]+)/gallery',
            component: BcGallery,
            props: true,
          }
        ]
      }
    ]
  },
  {
    path: '/:lang([a-z]{2})?/shared-document',
    meta: {
      netteRoute: '/<lang>/shared-document',
      trackPageView: false, // do not automatically track page view
    },
    component: FrontLayout,
    name: 'BCSharedDocument',
    children: [
      {
        path: '/:lang([a-z]{2})?/shared-document/:rootId/:id',
        component: Document,
        props: true,
        children: [
          {
            path: '/:lang([a-z]{2})?/shared-document/:rootId/:id/gallery',
            component: BcGallery,
            props: true,
          }
        ]
      }
    ]
  },
  {
    path: '/:lang([a-z]{2})?/search',
    meta: {
      netteRoute: '/<lang>/search',
    },
    component: FrontLayout,
    name: 'BCSearch',
    children: [
      {
        path: '/:lang([a-z]{2})?/search/:query?',
        component: Search,
        props: true,
      }
    ]
  },
  {
    path: '/:lang([a-z]{2})?/file',
    meta: {
      netteRoute: '/<lang>/file',
    },
    component: FrontLayout,
    name: 'BCFile',
    children: [
      {
        path: '/:lang([a-z]{2})?/file/:id',
        component: FrontLayout,
      }
    ]
  },
  {
    path: '/:lang([a-z]{2})?/trash-file',
    meta: {
      netteRoute: '/<lang>/trash-file',
    },
    component: FrontLayout,
    name: 'BCTrashFile',
    children: [
      {
        path: '/:lang([a-z]{2})?/trash-file/:id',
        component: FrontLayout,
      }
    ]
  },
  {
    path: '/:lang([a-z]{2})?/oauth2/:action/:ident',
    meta: {
      netteRoute: '/<lang>/oauth2/<action>/<ident>',
    },
    component: FrontLayout,
    name: 'BCOAuth',
  },
  {
    path: '/:lang([a-z]{2})?/',
    meta: {
      netteRoute: '/<lang>/',
    },
    component: FrontLayout,
    name: 'BCHome',
  },
  {
    path: '/:pathMatch(.*)*',
    component: FrontLayout,
  }
]

// create vue router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) { // smooth scroll to element with id
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: 'smooth',
            top: 200 // header height
          })
        }, 100);
      })
    
    } else if (savedPosition) {
      return savedPosition // if navigate back, forward -> use last known scroll position
    
    } else if (to.matched[0]?.name === 'BCDocument' && from.matched[0]?.name === 'BCDocument' && from.params.id === to.params.id) { // document revision changed
      return null // do not scroll

    } else if (to.path !== from.path) { // scroll to top
      return { top: 0, left: 0 }
    }
  },
})


router.beforeEach((to, from, next) => {
  if (store.state.menu)
    store.dispatch('menu/closeMenu')
  next()
})

router.afterEach((to) => {
  if (window.REFINER_WHATS_NEW_ID
    && window.REFINER_WHATS_NEW_ID !== 'REFINER_WHATS_NEW_ID'
    && to.name === 'BCHome') {
      
    window._refiner('showForm', window.REFINER_WHATS_NEW_ID)
  
  } else if (window.REFINER_SURVEY_ID
    && window.REFINER_SURVEY_ID !== 'REFINER_SURVEY_ID') {
    
    window._refiner('showForm', window.REFINER_SURVEY_ID)
  }
})

export default router
