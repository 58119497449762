// VUE app bootstrap for browser
import { createApp } from 'vue'
import App from './App.vue'
import context from './context'
import router from '@/router'
import netteBaseModule from 'nette-base-module'
import * as Sentry from "@sentry/vue";
import $mitt from './frontapp/emitter'

// create vue app instance
context.vueApp = createApp(App)

// init router
context.vueApp.use(router)

// init nette modules
context.vueApp.use(netteBaseModule, context)

if (context.prod) {
    Sentry.init({
        app: context.vueApp,
        dsn: "https://c8d3da55f7f550a8640c30620e5110fe@sentry.server.brandcloud.pro/2",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['localhost', /^\//],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

// adds brandcload global object $mitt to vue globalProperties
context.vueApp.config.globalProperties.$mitt = $mitt

// mount app to dom
context.vueApp.mount('#' + context.rootElementId)

